// External libraries
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
// Internal project-specific
import { ToastProps } from '../Components';
import { RootState } from '../redux-toolkit/reducer';
import { authSuccess, resetAuth } from '../redux-toolkit/reducer/authReducer';
import { setLoggedInUser, setResetUserCogn } from '../redux-toolkit/reducer/userReducer';
import { TOAST_FADEOUT, convertStringifiedJSON, getDecodedToken, signOut } from '../utils';
import { TENANT_ADMIN } from '../utils/constants';
import { getAllOrganizations, getOrganizationByOrgId } from '../redux-toolkit/reducer/organizationReducer';
interface SelectedOrgIdFromEmailInterface {
    selectedOrgId: string;
}

export const useKeepMeLoggedIn = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const { loggedInUserFromStore } = useSelector(
        ({ user }: RootState) => ({
            loggedInUserFromStore: user?.loggedInUser,
        }),
        _.isEqual,
    );

    const keepMeLoggedIn = (user: any, keepSignIn: boolean, setToastVals?: any) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            if (
                user.hasOwnProperty('challengeParam') &&
                user.challengeParam &&
                user.challengeParam.hasOwnProperty('userAttributes') &&
                user.challengeParam.userAttributes &&
                user.challengeParam.userAttributes.hasOwnProperty('email') &&
                user.challengeParam.userAttributes.email
            ) {
                localStorage.setItem('userEmail', user.challengeParam.userAttributes.email);
            } else if (
                user.hasOwnProperty('userAttributes') &&
                user.userAttributes &&
                user.userAttributes.hasOwnProperty('email') &&
                user.userAttributes.email
            ) {
                localStorage.setItem('userEmail', user.attributes.email);
            }
            //email id
            dispatch(setResetUserCogn(user));
            history.push({
                pathname: '/completeLogin',
            });
        } else {
            // assuming the user is logged in correctly.
            let loggedInUser = getDecodedToken(user.getSignInUserSession().getIdToken().getJwtToken()) as any;
            localStorage.setItem('keepSignedIn', JSON.stringify(keepSignIn));
            const storage = keepSignIn ? localStorage : sessionStorage;
            if (loggedInUser) {
                loggedInUser = convertStringifiedJSON(loggedInUser);
                if (loggedInUser?.roles?.includes(TENANT_ADMIN)) {
                    localStorage.setItem('wsConnectionEstablishedAt', JSON.stringify(Date.now()));
                    loggedInUser.currentApp ? (loggedInUser.currentApp = 'admin-app') : (loggedInUser.currentApp = '');
                    loggedInUser.currentApp = 'admin-app';
                    dispatch(authSuccess(loggedInUser));
                    dispatch(setLoggedInUser(loggedInUser));
                    storage.setItem('accessToken', user.signInUserSession.getAccessToken().getJwtToken());
                    storage.setItem('idToken', user.getSignInUserSession().getIdToken().getJwtToken());
                    storage.setItem('refreshToken', user.getSignInUserSession().getRefreshToken().getToken());
                    storage.setItem('uDetails', JSON.stringify(loggedInUser));
                    history.push('/tenants');
                    return;
                }
                let currentSelectedOrgPresent = {};
                if (
                    loggedInUser.hasOwnProperty('custom:currentSelectedOrg') &&
                    typeof Array.isArray(loggedInUser['custom:currentSelectedOrg']) &&
                    loggedInUser?.roles &&
                    !loggedInUser?.roles?.includes(TENANT_ADMIN) &&
                    loggedInUser['custom:currentSelectedOrg'].length === 0
                ) {
                    if (setToastVals) {
                        dispatch(resetAuth());
                        dispatch(setLoggedInUser({}));
                        signOut();
                        setToastVals({
                            type: 'error',
                            message: 'You do not have admin rights to any organization account',
                            msgHeader: 'Not Authorized!',
                            show: true,
                        });
                        setTimeout(() => {
                            setToastVals({
                                type: '',
                                message: '',
                                msgHeader: '',
                                show: false,
                            } as ToastProps);
                        }, TOAST_FADEOUT);
                    }
                    return;
                } else if (
                    loggedInUser.hasOwnProperty('orgsPrivileges') &&
                    typeof Array.isArray(loggedInUser.orgsPrivileges) &&
                    loggedInUser?.roles &&
                    !loggedInUser?.roles?.includes(TENANT_ADMIN)
                ) {
                    if (loggedInUserFromStore?.currentSelectedOrg) {
                        currentSelectedOrgPresent = loggedInUser.orgsPrivileges.find(
                            (item: any) => item.org_id === loggedInUserFromStore.currentSelectedOrg.org_id,
                        );
                    }
                    if (currentSelectedOrgPresent && Object.keys(currentSelectedOrgPresent).length > 0) {
                        loggedInUser.currentSelectedOrg = loggedInUserFromStore.currentSelectedOrg;
                    } else {
                        loggedInUser.adminOfOrgs = [];
                        const orgPrivNewArr = [] as any;
                        loggedInUser.orgsPrivileges.forEach((item: any) => {
                            if (
                                item?.privileges.includes('admin-app') ||
                                item?.privileges.includes('admin-user-management')
                            ) {
                                loggedInUser.adminOfOrgs.push(item.org_id);
                                orgPrivNewArr.push(item);
                            }
                        });
                        if (orgPrivNewArr.length === 0) {
                            dispatch(resetAuth());
                            dispatch(setLoggedInUser({}));
                            signOut();
                            if (setToastVals) {
                                setToastVals({
                                    type: 'error',
                                    message:
                                        'Your account is not authorized for this application, please contact the administrator',
                                    msgHeader: 'Not Authorized!',
                                    show: true,
                                });
                                setTimeout(() => {
                                    setToastVals({
                                        type: '',
                                        message: '',
                                        msgHeader: '',
                                        show: false,
                                    } as ToastProps);
                                }, TOAST_FADEOUT);
                            }
                            return;
                        }
                        loggedInUser.orgsPrivileges = JSON.parse(JSON.stringify(orgPrivNewArr));
                        let selectedOrgIdObj;
                        const locationState = location?.state as SelectedOrgIdFromEmailInterface;
                        if (locationState?.selectedOrgId !== null && locationState?.selectedOrgId !== undefined) {
                            selectedOrgIdObj = locationState.selectedOrgId
                                ? loggedInUser.orgsPrivileges.find(
                                      (org: any) => org.org_id === locationState.selectedOrgId,
                                  )
                                : '';
                            if (selectedOrgIdObj) {
                                loggedInUser.currentSelectedOrg = selectedOrgIdObj;
                            } else loggedInUser.currentSelectedOrg = loggedInUser.orgsPrivileges[0];
                        } else if (loggedInUser?.identities?.length > 0) {
                            loggedInUser.currentSelectedOrg = loggedInUser.orgsPrivileges.find(
                                (org: any) => org.orgName === loggedInUser?.identities[0].providerName,
                            );
                        } else {
                            loggedInUser.currentSelectedOrg =
                                loggedInUser['custom:currentSelectedOrg'] ?? loggedInUser.orgsPrivileges[0];
                        }
                    }
                }
                // initiate the WS with unique identifier to be passed to WS connection
                localStorage.setItem('wsConnectionEstablishedAt', JSON.stringify(Date.now()));
                loggedInUser.currentApp = 'admin-app';
                dispatch(authSuccess(loggedInUser));
                dispatch(setLoggedInUser(loggedInUser));
                storage.setItem('accessToken', user.signInUserSession.getAccessToken().getJwtToken());
                storage.setItem('idToken', user.getSignInUserSession().getIdToken().getJwtToken());
                storage.setItem('refreshToken', user.getSignInUserSession().getRefreshToken().getToken());
                storage.setItem('uDetails', JSON.stringify(loggedInUser));
                dispatch(getOrganizationByOrgId(loggedInUser.currentSelectedOrg.org_id));
                dispatch(getAllOrganizations(100));
                if (
                    Object.keys(currentSelectedOrgPresent).length > 0 &&
                    history.location.pathname &&
                    history.location.pathname.indexOf('login') < 0
                ) {
                    history.push({
                        pathname: history.location.pathname,
                    });
                } else {
                    history.push({
                        pathname: '/',
                    });
                }
                //return loggedInUser;
            } else {
                signOut();
                if (setToastVals) {
                    setToastVals({
                        type: 'error',
                        message: 'Role for the entered user not found.',
                        msgHeader: 'Role Missing!',
                        show: true,
                    });
                    setTimeout(() => {
                        setToastVals({ type: '', message: '', msgHeader: '', show: false } as ToastProps);
                    }, TOAST_FADEOUT);
                }
            }
        }
    };

    return { keepMeLoggedIn };
};
