import { apiPost } from './axios';
import { ENDPOINT_DEV } from './configs';

export const sendDownloadRecordingLinksEmailAPI = async (orgPortal: any) => {
    return await apiPost(
        `${ENDPOINT_DEV}organization/${orgPortal?.orgId}/portal/${orgPortal?.portalId}/email-recording-links`,
        {
            recordingIds: orgPortal?.recordingIds,
        },
    );
};
